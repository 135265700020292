
@import '../../assets/css/style.styl'

.nav-bg
 top: 0px; right: 0px; bottom: 0px; left: 0px;
 position: absolute;
 background white
 width: 100%
 z-index: 10

.nav-black
  border-bottom: 1px solid $color-gray-border
  width: 100%
  margin: 0
  top: 0
  z-index: 50
  background-color: white

.nav-countdown
  color: white
  width: 100%
  margin: 0
  top: 0
  z-index: 50

.nav-countdown::before {
    content: "";
    top: 0px; right: 25px; bottom: 25px; left: 25px;
    position: absolute;
    display: block;
    background: rgba(0,0,0,0.3);
    box-shadow: 0 0 0.8em 0.8em rgba(0,0,0,0.3);
}

.nav-countdown-black
  color: black
  width: 100%
  margin: 0
  top: 0
  z-index: 50

.nav-countdown-black::before {
    content: "";
    top: 0px; right: 25px; bottom: 25px; left: 25px;
    position: absolute;
    display: block;
    background: white;
}

.fixed
  position: fixed

.z-20
    z-index 20

.social-link
    width: 50px

@media screen and (max-width: 600px)
  .logo
    height: 30px
  .social-link
    width: 50px
    max-height: 20px

  .text-head
    font-size: 1.5rem !important


@media screen and (max-width: 1264px)
  .container
    max-width: 1085px !important
  .social-link
    width: 50px
    max-height: 10px


@media screen and (max-width: 1440px)
  .container
    max-width: 1400px !important

@media screen and (min-width: 1480px)
  .container
    max-width: 1685px !important


@media screen and (max-width: 768px)
  .container
    max-width: 600px !important
